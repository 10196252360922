import types from './types';

const setProfile = (profile) => ({ type: types.setProfile, profile });
const updateProfile = (profile) => ({ type: types.updateProfile, profile });
const updateUser = (afId) => ({ type: types.updateUser, afId });
const saveProfile = (profile) => ({ type: types.saveProfile, profile });
const saveProfileSuccess = (profile) => ({ type: types.saveProfileSuccess, profile });
const saveProfileFailure = (error) => ({ type: types.saveProfileFailure, error });
const setEdit = () => ({ type: types.setEdit });
const getProfile = () => ({ type: types.getProfile });
const getProfileFailure = (error) => ({ type: types.getProfileFailure, error });
const setEmailReminder = (isVerified) => ({ type: types.setEmailReminder, isVerified });
const setDailyCoins = (coins) => ({ type: types.setDailyCoins, coins });
const setModalNotice = (notice = null) => ({ type: types.setModalNotice, notice });
const acceptModalAlert = () => ({ type: types.acceptModalAlert });
const declineModalAlert = () => ({ type: types.declineModalAlert });
const sendVerifyMail = () => ({ type: types.sendVerifyMail });
const sendVerifyMailSuccess = () => ({ type: types.sendVerifyMailSuccess });
const sendVerifyMailFailure = (error) => ({ type: types.sendVerifyMailFailure, error });
const changeEmail = (newEmail) => ({ type: types.changeEmail, newEmail });
const changeEmailSuccess = (newEmail) => ({ type: types.changeEmailSuccess, newEmail });
const changeEmailFailure = (error) => ({ type: types.changeEmailFailure, error });
const updateLead = ({ gender, geoId, birthday, fileList, currentPreview }) => ({
  type: types.updateLead,
  gender,
  birthday,
  fileList,
  geoId,
  currentPreview
});
const updateLeadSuccess = (fileList, currentPreview) => ({
  type: types.updateLeadSuccess,
  fileList,
  currentPreview
});
const updateLeadFailure = (error) => ({ type: types.updateLeadFailure, error });
const updateLeadFinish = () => ({
  type: types.updateLeadFinish,
});
const updateLeadFinishSuccess = (images) => ({ type: types.updateLeadFinishSuccess, images });
const updateLeadFinishFailure = (error) => ({ type: types.updateLeadFinishFailure, error });
const setStatement = (statement) => ({type:types.setStatement, statement});
const setStatementSuccess = ({statement}) => ({type:types.setStatementSuccess, statement});
const setStatementFailure = (error) => ({type:types.setStatementFailure, error});
const shownRegisterModal = () => ({type: types.shownRegisterModal});
const setHasNoPicture = (hasNoProfilePicture) => ({type: types.setHasNoPicture, hasNoProfilePicture});
const setProfilePic = (path) => ({type: types.setProfilePic, path});
const updateLocation = (id, cityName, price, isFirst) => ({type: types.updateLocation, id, cityName, price, isFirst});
const updateLocationSuccess = cityName => ({type: types.updateLocationSuccess, cityName});
const updateLocationFailure = error => ({type: types.updateLocationFailure, error});

export default {
  setProfile,
  updateProfile,
  updateUser,
  saveProfile,
  saveProfileSuccess,
  saveProfileFailure,
  setEdit,
  getProfile,
  getProfileFailure,
  setEmailReminder,
  setDailyCoins,
  setModalNotice,
  acceptModalAlert,
  declineModalAlert,
  changeEmail,
  changeEmailSuccess,
  changeEmailFailure,
  sendVerifyMail,
  sendVerifyMailSuccess,
  sendVerifyMailFailure,
  updateLead,
  updateLeadSuccess,
  updateLeadFailure,
  updateLeadFinish,
  updateLeadFinishSuccess,
  updateLeadFinishFailure,
  setStatement,
  setStatementSuccess,
  setStatementFailure,
  shownRegisterModal,
  setHasNoPicture,
  setProfilePic,
  updateLocation,
  updateLocationSuccess,
  updateLocationFailure,
  updateUser
};
