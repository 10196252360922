import packageJson from './../package.json';

const Config = {
  dev: true,
  api: {
    url:
      process.env.NODE_ENV === 'production'
        ? 'https://api.natchd.com/api/'
        : 'https://api.natchd.com/api/',
  },
  cacheAmount: 10,
  urls: {
    imprint: 'https://natchd.com/impressum/',
    privacy: 'https://natchd.com/datenschutz/',
    tos: 'https://natchd.com/agb',
    faq: 'https://natchd.com/faq',
    guidelines: 'https://natchd.com/richtlinien/',
    googlePlaystore: '#',
    appleStore: '#'
  },
  places: {
    key: 'AIzaSyBByHujFBPdQqe1Awp5D8CSjOXVxUiYcTo',
  },
  maxImagePrice: 10000,
  s3: {
    dirName: 'app/tickets/media_upload',
    bucketName:
      process.env.NODE_ENV === 'production'
        ? 'media.icatchedfiles.de'
        : 'mediadev.icatchedfiles.de',
    region: 'eu-central-1',
    accessKeyId: 'AKIA257POBS7WP7C2A74',
    secretAccessKey: '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',
    s3Url:
      process.env.NODE_ENV === 'production'
        ? 'https://s3.eu-central-1.amazonaws.com/media.icatchedfiles.de/'
        : 'https://s3.eu-central-1.amazonaws.com/mediadev.icatchedfiles.de/',
  },
  versionNumber: `Version ${packageJson.version}`,
  appName: 'Natchd',
  appCurrency: 'Diamanten',
  appCurrencySymbol: '💎',
  acceptedTypes: ['image/jpg', 'image/jpeg', 'image/png'],
  appsFlyer: {
    webKey: 'c337d57d-ab0b-40ac-8cfd-796c6cbf30e8',
  },
  fbAppID: '277450333754649',
  googleClientID: '736249156738-km2ug0pomq0bhpbc3smelgadeg1h586n.apps.googleusercontent.com',
  recaptcha: {
    webKey: '6LdYoCUeAAAAAFM2qKCbSbBCIPQelYwoBzOp_S2C',
    secretKey: '6LdYoCUeAAAAAFHOnSIvmm_cQacvrSxm580jf5aX',
  },
};

export default Config;
