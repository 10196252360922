import { css } from 'styled-components';

const boxShadowHigh = css`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
`;

const boxShadowLow = css`
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.3);
`;

const boxShadowButton = css`
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
`;

const boxShadowButtonHover = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
`;

const landingShadow = css`
  box-shadow: 0 0px 40px 0 rgba(235, 85, 105, 0.3);
`;

const backgroundGradient = css`
  background: linear-gradient(135deg, #956d58, #be917a 50%, #956d58);
`;

const buttonGradient = css`
  background: linear-gradient(to right, #7ac8f7, #cce2f0 40%, #7ac8f7);
`;

const surfaceGradient = css`
  background-image: linear-gradient(#f2f0f0, #ffffff);
`;

const natchdGradient = css`
  background: linear-gradient(#e94771, #f29950);
`;

export default {
  colors: {
    primary: '#eb5569',
    primaryVariantLight: '#66E284',
    primaryVariantUltraLight: '#66E284',
    primaryVariantDark: '#39C45B',
    primaryGradient: '#40DB66',
    secondary: '',
    secondaryVariant: '',
    background: '#ffffff',
    backgroundFooterLight: '#ffffff',
    backgroundFooterDark: '#696969',
    surface: '#ffffff',
    // error: '#F7CCCD',
    error: 'lightgrey',
    footer: '#282c34',
    fontOnPrimary: '#ffffff',
    fontOnSecondary: '#000000',
    fontOnBackground: '#ffffff',
    fontOnSurface: '#000000',
    // fontOnError: '#ffffff',
    fontOnError: '#000000',
    fontOnFooterDark: '#ffffff',
    facebook: '#1778F2',
    google: '#DD4B39 ',
    disabled: '#F5BABC',
    yellow: '#F5A623',
    lightGrey: '#fbfbfb',
    dimGrey: '#EFF3F4',
    accept: '#1D9D3E',
    decline: '#D70005',
    grey: '#636161',
    currency: '#00B0FF',
  },
  images: {
    header_background: '/static/images/header_background.png',
  },
  shadows: {
    high: '0px 2px 4px 0px rgba(0, 0, 0, 0.5)',
    none: '0px 0px 0px 0px rgba(0, 0, 0, 0.5)',
  },
  borders: {
    button: '3px',
    input: '5px',
    images: '5px',
    contentBox: '3px',
    message: '10px',
    roundedButton: '25px',
    inputBox: '3px',
    navButton: '5px',
    navButtonLeft: '5px 0px 0px 5px',
    navButtonRight: '0px 5px 5px 0px',
    registerModal: '10px 10px 0px 0px',
  },
  boxShadowLow,
  boxShadowHigh,
  backgroundGradient,
  boxShadowButton,
  boxShadowButtonHover,
  buttonGradient,
  surfaceGradient,
  landingShadow,
  natchdGradient,
};
