const themeLight = {
  main: '#eb5569',
  chatBubble: '#eb5569',
  mainVariantLight: '#66E284',
  mainVariantDark: '#39C45B',
  mainVariantUltraLight: '#66E284',
  backgroundPrimary: '#F2F4F9',
  backgroundSecondary: '#ffffff',
  backgroundFooterLight: 'ffffff',
  backgroundFooterDark: '#696969',
  fontOnPrimary: '#000',
  fontOnPrimaryLight: '#000',
  fontOnSecondary: '#fff',
  fontOnMain: '#fff',
  background: '#eb5569',
  backgroundGradient: 'linear-gradient(#e94771, #f29950)',
  noBackground: '#fff',
  noBackgroundSecondary: '#fff',
  marker: '#D82D2D',
  markerText: '#fff',
  input: '#fff',
  inputFont: '#000',
  inputIcon: '#eb5569',
  inputDark: '#EFF3F4',
  icon: '#eb5569',
  loaderLight: '#FFFFFF',
  loaderDark: '#F5F5F5',
  splitter: '#e6e6e6',
  chatInfo: '#D1E7F9',
  chatInfoText: '#000',
  tileFontPrimary: '#eb5569',
  tileFontSecondary: '#363738',
  blockOverlay: '#EFF3F4',
  blockOverlayFont: '#000',
  paymentOption: '#ffffff',
  externalLink: '#eb5569',
};

const themeDark = {
  main: '#eb5569',
  chatBubble: '#eb5569',
  mainVariantLight: '#66E284',
  mainVariantDark: '#39C45B',
  mainVariantUltraLight: '#66E284',
  backgroundPrimary: '#2E2F30',
  backgroundSecondary: '#363738',
  backgroundFooterLight: '#363738',
  backgroundFooterDark: '#2E2F30',
  fontOnPrimary: '#fff',
  fontOnPrimaryLight: '#fff',
  fontOnSecondary: '#fff',
  fontOnMain: '#fff',
  background: '#eb5569',
  backgroundGradient: 'linear-gradient(#e94771, #f29950)',
  noBackground: '#2E2F30',
  noBackgroundSecondary: '#363738',
  marker: '#D82D2D',
  markerText: '#fff',
  input: '#4C4D4F',
  inputFont: '#fff',
  inputIcon: '#eb5569',
  inputDark: '#4C4D4F',
  icon: '#eb5569',
  loaderLight: '#4C4D4F',
  loaderDark: '#363738',
  splitter: '#4C4D4F',
  chatInfo: '#4C4D4F',
  chatInfoText: '#fff',
  tileFontPrimary: '#fff',
  tileFontSecondary: '#D3D3D3',
  blockOverlay: '#4C4D4F',
  blockOverlayFont: '#fff',
  paymentOption: '#363738',
  externalLink: '#eb5569',
};

export default { themeLight, themeDark };
