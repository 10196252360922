import React from 'react';
import styled from 'styled-components';
import { ThemedText, RoundedDiv } from './ThemedComponents';
import StyleGuide from './StyleGuide';

const TabDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 500px;
  margin-bottom: 15px;
  border-radius: ${StyleGuide.borders.navButton}
`;

const BottomFlatDiv = styled(RoundedDiv)`
  border-radius: ${props => props.borderRadius};
  background-color: ${props => (props.secondary ? props.theme.secondary : props.theme.primary)};
  align-self: stretch;
  padding: 5px 0px;
  flex: 1;
  cursor: pointer;
  ${StyleGuide.boxShadowHigh}
  @media only screen and (max-width: 414px) {
    padding: 5px 0px;
  }
`;
/**
 * Custom Tab Bar for Navigation
 */
const TabBar = ({ leftTabTitle, leftTabClick, rightTabTitle, rightTabClick, leftVisible }) => {
  return (
    <TabDiv>
      <BottomFlatDiv
        onClick={leftTabClick}
        secondary={!leftVisible}
        borderRadius="10px 0px 0px 10px"
      >
        <ThemedText size="17px" secondary={!leftVisible}>
          {leftTabTitle}
        </ThemedText>
      </BottomFlatDiv>
      <BottomFlatDiv
        onClick={rightTabClick}
        secondary={leftVisible}
        borderRadius="0px 10px 10px 0px"
      >
        <ThemedText size="17px" secondary={leftVisible}>
          {rightTabTitle}
        </ThemedText>
      </BottomFlatDiv>
    </TabDiv>
  );
};

export default TabBar;
