import React, { memo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { Text } from './ThemedComponents';
import Icon from './Icon';

const Content = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: fixed;
  width: 320px;
  left: 50%;
  top: 50%;
  z-index: 100;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  /* background: ${(props) => props.theme.background || 'lightgrey'}; */
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 120px;
`;

/**
 * Alert Component with auto disable
 */
const ConfirmOverlay = ({ iconName, text, image, trigger }) => {
  const [show, setShow] = useState(false);
  const prevTrigger = useRef(trigger);

  const props = useSpring({
    to: [
      { opacity: 0.9, filter: 'blur(0px)', transform: 'scale(1)' },
      { opacity: 0, filter: 'blur(10px)', transform: 'scale(1.2)' },
    ],
    from: { opacity: 0, filter: 'blur(10px)', transform: 'scale(1.2)' },
    config: { tension: '90' },
  });

  useEffect(() => {
    let timeoutId = null;
    function hide() {
      setShow(false);
    }
    if (trigger && !prevTrigger.current) {
      setShow(true);
      timeoutId = setTimeout(hide, 2000);
    }
    prevTrigger.current = trigger;
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      hide();
    };
  }, [trigger]);

  if (!show) {
    return null;
  }

  return (
    <Content as={animated.div} style={props} visible>
      {image && <img src={image} width="50px" height="50px" alt="pic" />}
      {iconName && <Icon name={iconName} />}
      <Text style={{ fontWeight: 'bolder' }}>{text}</Text>
    </Content>
  );
};

export default memo(ConfirmOverlay);
