import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSpring, animated, useSprings } from 'react-spring';
import styled from 'styled-components';
import { useDrag } from 'react-use-gesture';
import { withTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Text } from './ThemedComponents';
import { CustomAlert, StyleGuide } from '.';
import Icon from './Icon';
import { withCoinAmountCheck } from '../CoinWatcher';
import useDimensions from '../hooks/useDimensions';
import Tracking from '../tracking';
import Config from '../config';

const GalleryOverlayContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-radius: ${StyleGuide.borders.images};
  max-width: 100%;
  opacity: 1;
  z-index: 10;
  @media only screen and (max-width: 415px) {
    height: 100%;
    padding: 5px;
  }
`;

const TestBackground = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const IconCircle = styled.div`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
`;

const IconCircleAbsolute = styled(IconCircle)`
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
`;

const AbsoluteDescription = styled.div`
  display: flex;
  position: absolute;
  left: auto;
  margin: 10px 0px 20px 0px;
  border-radius: ${({ isCircle }) => (isCircle ? '50%' : StyleGuide.borders.images)};
  /* padding: 2px 5px; */
  background: white;
  color: dimgrey;
  bottom: 20px;
  top: ${(props) => (props.locked ? '0' : '')};
`;

const CenterImageContainer = styled.div`
  width: 500px;
  height: 500px;
  margin-top: 10px;
  margin-bottom: 22px;
  object-fit: contain;
  position: relative;
  max-width: 100%;
  border-radius: ${StyleGuide.borders.images};
  overflow: hidden;
  background-color: black;
`;

const Image = styled(animated.img)`
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  /* user-select: none; */
`;

const AbsoluteIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
`;

function mod(n, m) {
  return ((n % m) + m) % m;
}

function getStyleFromIndex(index, shownIndex, width, length) {
  // mid Pic
  if (index === shownIndex) {
    return {
      display: 'block',
      x: 0 * width,
      opacity: 1,
    };
  }
  // left Pic
  if (shownIndex - index === 1 || (shownIndex === 0 && index === length - 1)) {
    return {
      display: 'block',
      x: -1 * width,
      opacity: 0,
    };
  }
  // right Pic
  if (index - shownIndex === 1 || (shownIndex === length - 1 && index === 0)) {
    return {
      display: 'block',
      x: 1 * width,
      opacity: 0,
    };
  }

  return {
    display: 'none',
    x: (index - shownIndex) * width,
    opacity: 0,
  };
}
/**
 * ImageView Modal used by Galleries
 */
const GalleryModal = ({
  t,
  onRequestClose,
  images,
  currentPhotoIndex,
  hasEnoughCoins,
  otherGallery,
  unlockItem,
  userId,
  userName,
  sendFriendRequest,
  canSendFriendRequest,
}) => {
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });
  const router = useRouter();

  const isSingle = useMemo(() => images?.length === 1, [images?.length]);
  const isDouble = useMemo(() => images?.length === 2, [images?.length]);

  const showedImages = useMemo(() => (isDouble ? [...images, ...images] : images), [
    isDouble,
    images,
  ]);

  const indexRef = useRef(currentPhotoIndex || 0);
  const [windowRef, { width: imageWidth }] = useDimensions();
  const [imageSprings, set] = useSprings(showedImages.length, (index) => ({
    x: (index - indexRef.current) * (imageWidth || 1000),
    opacity: 0,
    config: { mass: 2 },
  }));
  useEffect(() => {
    set((index) => getStyleFromIndex(index, indexRef.current, imageWidth, showedImages.length));
  }, [imageWidth, set, showedImages.length]);
  const bind = useDrag(({ movement: [moveX], cancel, down, canceled }) => {
    if (isSingle) return;
    if (Math.abs(moveX) > imageWidth / 3 && !canceled) {
      cancel();
      indexRef.current = mod(indexRef.current + (moveX < 0 ? 1 : -1), showedImages.length);
      setIndex(indexRef.current);
    }
    set((index) =>
      getStyleFromIndex(
        index,
        indexRef.current,
        imageWidth + (down ? moveX : 0),
        showedImages.length
      )
    );
  });

  const move = useCallback(
    (right) => {
      const newIndex = mod(indexRef.current + (right ? 1 : -1), showedImages.length);
      indexRef.current = newIndex;
      setIndex(newIndex);
      set((index) => getStyleFromIndex(index, newIndex, imageWidth, showedImages.length));
    },
    [imageWidth, showedImages.length, set]
  );
  const [currentIndex, setIndex] = useState(currentPhotoIndex || 0);
  const currentPicture = useMemo(() => showedImages[currentIndex], [currentIndex, showedImages]);
  const [buyAlert, toggleBuyAlert] = useState(false);
  const [friendAlert, toggleFriendAlert] = useState(false);

  return (
    <TestBackground as={animated.div} style={props}>
      <GalleryOverlayContainer>
        {!isSingle && (
          <IconCircle onClick={() => move(false)}>
            <Icon name="chevron-left" size="25" color="white" />
          </IconCircle>
        )}
        <CenterImageContainer ref={windowRef} {...bind()}>
          {isSingle ? (
            <Image
              // style={{ display, opacity, transform: x.interpolate(x => `translateX(${x}px)`) }}
              key={showedImages[0].id}
              src={showedImages[0].image.uri}
              draggable={false}
            />
          ) : (
            imageSprings.map(({ x, display, opacity }, index) => (
              <Image
                style={{ display, opacity, transform: x.interpolate((x) => `translateX(${x}px)`) }}
                draggable={false}
                key={showedImages[index].id}
                src={showedImages[index].image.uri}
              />
            ))
          )}
        </CenterImageContainer>
        {!isSingle && (
          <IconCircle onClick={() => move(true)}>
            <Icon name="chevron-right" size="25" color="white" />
          </IconCircle>
        )}
        <IconCircleAbsolute onClick={() => onRequestClose(false)}>
          <Icon name="decline" size="15" color="dimgrey" />
        </IconCircleAbsolute>
        <AbsoluteDescription isCircle={!currentPicture?.isBillable}>
          {currentPicture?.isBillable && (
            <Text style={{ fontWeight: 'bolder' }} color="inherit">
              {`${currentPicture?.price} ${Config.appCurrencySymbol}`}
            </Text>
          )}
          {currentPicture?.isProfile && (
            <Icon name="profile" size="20" margin="5px" color="inherit" />
          )}
          {currentPicture?.onlyForFriends && (
            <Icon name="friends" size="20" margin="5px" color="inherit" />
          )}
        </AbsoluteDescription>
        {currentPicture?.onlyForFriends && otherGallery && (
          <AbsoluteIconBox onClick={() => toggleFriendAlert(true)}>
            <Icon name="friends" color="white" size="60" />
          </AbsoluteIconBox>
        )}
        {currentPicture?.locked && !currentPicture?.onlyForFriends && otherGallery && (
          <AbsoluteIconBox>
            <Icon name="locked" color="white" size="60" />
          </AbsoluteIconBox>
        )}
        {currentPicture?.isBillable && currentPicture?.locked && otherGallery && (
          <AbsoluteIconBox
            onClick={() => {
              toggleBuyAlert(true);
            }}
          >
            <Icon name="locked" color="white" size="60" />
          </AbsoluteIconBox>
        )}
        <CustomAlert
          show={buyAlert}
          singleButton
          closeable
          setShow={toggleBuyAlert}
          onAccept={() => {
            if (hasEnoughCoins(currentPicture?.price)) {
              unlockItem(currentPicture?.id, currentPicture?.price);
              Tracking.track('profileUnlock', { imagePrice: currentPicture?.price });
            } else {
              router.push('/payment');
            }
            toggleBuyAlert(false);
          }}
          alertTitle={t('unlockImage')}
          picName="camera"
          alertText={t('unlockTextImage', { name: userName })}
          acceptText={t('unlock', { count: currentPicture?.price })}
        />
        <CustomAlert
          show={friendAlert}
          onAccept={() => {
            if (canSendFriendRequest) {
              sendFriendRequest(userId);
            } else {
              router.push(
                {
                  pathname: router.pathname,
                  query: router?.query
                    ? { ...router.query, withFriendModal: true }
                    : { withFriendModal: true },
                },
                undefined,
                { shallow: true }
              );
            }
            toggleFriendAlert(false);
          }}
          alertTitle={t('unlockImage')}
          onDecline={() => toggleFriendAlert(false)}
          picName="camera"
          acceptText={t('sendRequestQuestion')}
          alertText={t('unlockImageFriend', { name: userName })}
        />
      </GalleryOverlayContainer>
    </TestBackground>
  );
};

export default withTranslation(['userProfile', 'profile', 'values'])(
  withCoinAmountCheck(GalleryModal)
);
