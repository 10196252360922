export default {
  searchProfile: {
    category: 'in_app_action_exploration',
    action: 'exploration_search_users'
  },
  searchSendMessage: {
    category: 'in_app_action_search',
    action: 'search_to_chat'
  },
  searchLike: {
    category: 'in_app_action_search',
    action: 'search_like_profile'
  },
  searchAddFriend: {
    category: 'in_app_action_search',
    action: 'search_friend_request'
  },
  searchClick: {
    category: 'in_app_action_search',
    action: 'search_to_profile'
  },
  friendsNewSlots: {
    25: {
      category: 'in_app_action_social',
      action: 'social_friends_new_slots_25'
    },
    50: {
      category: 'in_app_action_social',
      action: 'social_friends_new_slots_50'
    },
    100: {
      category: 'in_app_action_social',
      action: 'social_friends_new_slots_100'
    }
  },
  visitorUnlock: {
    category: 'in_app_action_social',
    action: 'visitors_unlock_profile'
  },
  profileUnlock: {
    category: 'in_app_action_profile',
    action: 'user_profile_unlock_image'
  },
  profileGallery: {
    category: 'in_app_action_profile',
    action: 'user_profile_open_gallery'
  },
  profileIcebreaker: {
    category: 'in_app_action_profile',
    action: 'user_profile_icebreaker'
  },
  profileAddFriend: {
    category: 'in_app_action_profile',
    action: 'user_profile_add_friend'
  },
  profileSendMessage: {
    category: 'in_app_action_profile',
    action: 'user_profile_to_chat'
  },
  profilePresent: {
    category: 'in_app_action_profile',
    action: 'user_profile_send_gift'
  },
  profileLike: {
    category: 'in_app_action_profile',
    action: 'user_profile_like'
  },
  ownProfilePicture: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_gallery'
  },
  ownProfileGallery: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_gallery'
  },
  ownProfileEdit: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_edit_details'
  },
  ownProfileEditAboutMe: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_edit_about_me'
  },
  ownProfileEditImage: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_edit_image'
  },
  ownProfileCoins: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_to_payment'
  },
  ownProfileFriends: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_friends'
  },
  ownProfileGifts: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_gifts'
  },
  ownProfileUpload: {
    category: 'in_app_action_own_profile',
    action: 'own_profile_add_image'
  },
  sendGift: {
    category: 'in_app_action_gifts',
    action: 'send_gift'
  },
  votingLike: {
    category: 'in_app_action_voting',
    action: 'voting_like'
  },
  votingDislike: {
    category: 'in_app_action_voting',
    action: 'voting_dislike'
  },
  votingMessage: {
    category: 'in_app_action_voting',
    action: 'voting_send_message'
  },
  login: {
    facebook: {
      category: 'login_action',
      action: 'login_facebook'
    },
    email: {
      category: 'login_action',
      action: 'login_email'
    },
    emailContinue: {
      category: 'login_action',
      action: 'login_email_continue'
    }
  },
  purchase: {
    category: 'in_app_action_payment',
    action: 'payment_product_purchase'
  },
  reportUser: {
    category: 'in_app_action_report_user',
    action: 'report_user'
  },
  settingsImprint: {
    category: 'in_app_action_settings',
    action: 'settings_imprint'
  },
  settingsFaq: {
    category: 'in_app_action_settings',
    action: 'settings_faq'
  },
  settingsTOS: {
    category: 'in_app_action_settings',
    action: 'settings_terms_of_service'
  },
  settingsDataProtection: {
    category: 'in_app_action_settings',
    action: 'settings_data_protection'
  },
  settingsGuidelines: {
    category: 'in_app_action_settings',
    action: 'settings_guidelines'
  },
  settingsSignOut: {
    category: 'in_app_action_settings',
    action: 'settings_sign_out'
  },
  matchesSendMessage: {
    category: 'in_app_action_matches',
    action: 'matches_to_message'
  },
  matchesAddFriend: {
    category: 'in_app_action_matches',
    action: 'matches_add_friend'
  },
  matchesClick: {
    category: 'in_app_action_matches',
    action: 'matches_to_profile'
  },
  conversationSendMessage: {
    category: 'in_app_action_conversation',
    action: 'conversation_send_message'
  },
  conversationSendMessageLength: {
    category: 'in_app_action_conversation',
    action: 'conversation_send_message_length'
  },
  quickConversationSendMessage: {
    category: 'in_app_action_quick_conversation',
    action: 'quick_conversation_send_message'
  },
  quickConversationOpen: {
    category: 'in_app_action_quick_conversation',
    action: 'quick_conversation_open'
  },
  quickConversationToChat: {
    category: 'in_app_action_quick_conversation',
    action: 'quick_conversation_to_conversation'
  },
  //
  // AB HIER ZUSÄTZLICHE GTM EVENTS
  //
  filterClick: {
    category: 'in_app_action_notification',
    action: 'filter_clicked'
  },
  profileNearClick: {
    category: 'in_app_action_profile_near_me',
    action: 'profile_near_me_to_profile'
  },
  profileNearSendMessage: {
    category: 'in_app_action_profile_near_me',
    action: 'profile_near_me_to_chat'
  },
  profileNearLike: {
    category: 'in_app_action_profile_near_me',
    action: 'profile_near_me_like_profile'
  },
  profileNearFriend: {
    category: 'in_app_action_profile_near_me',
    action: 'profile_near_me_friend_request'
  },
  visitorSlideUnlock: {
    category: 'in_app_action_visitor_slider',
    action: 'visitor_slider_unlock_profile'
  },
  visitorSlideProfile: {
    category: 'in_app_action_visitor_slider',
    action: 'visitor_slider_to_profile'
  },
  conversationSendGift: {
    category: 'in_app_action_conversation',
    action: 'conversation_send_gift'
  },
  conversationSendIcebreaker: {
    category: 'in_app_action_conversation',
    action: 'conversation_send_icebreaker'
  },
  conversationProfile: {
    category: 'in_app_action_conversation',
    action: 'conversation_to_profile'
  },
  conversationDelete: {
    category: 'in_app_action_conversation',
    action: 'conversation_delete'
  },
  conversationReport: {
    category: 'in_app_action_conversation',
    action: 'conversation_report_user'
  },
  friendClick: {
    category: 'in_app_action_friends',
    action: 'friends_to_profile'
  },
  friendSendMessage: {
    category: 'in_app_action_friends',
    action: 'friends_to_chat'
  },
  friendDelete: {
    category: 'in_app_action_friends',
    action: 'friends_delete_friend'
  },
  friendLike: {
    category: 'in_app_action_friends',
    action: 'friends_like_profile'
  },
  friendAccept: {
    category: 'in_app_action_friends',
    action: 'friends_accept_request'
  },
  friendDecline: {
    category: 'in_app_action_friends',
    action: 'friends_decline_request'
  },
  friendRetreat: {
    category: 'in_app_action_friends',
    action: 'friends_retreat_request'
  },
  visitorClick: {
    category: 'in_app_action_visitors',
    action: 'visitors_to_profile'
  },
  presentsClick: {
    category: 'in_app_action_presents',
    action: 'presents_to_profile'
  },
  presentsModalClick: {
    category: 'in_app_action_presents',
    action: 'presents_open_overview'
  },
  landingRegister: {
    category: 'in_app_action_landing_page',
    action: 'landing_register_for_free'
  },
  landingFBLogin: {
    category: 'in_app_action_landing_page',
    action: 'landing_facebook_login'
  },
  landingOpenLogin: {
    category: 'in_app_action_landing_page',
    action: 'landing_open_login_bar'
  },
  landingLogin: {
    category: 'in_app_action_landing_page',
    action: 'landing_login'
  },
  landingStayLogged: {
    category: 'in_app_action_landing_page',
    action: 'landing_stay_logged_in'
  },
  landingForgot: {
    category: 'in_app_action_landing_page',
    action: 'landing_forgot_password'
  },
  landingFBRegister: {
    category: 'in_app_action_landing_page',
    action: 'landing_register_with_facebook'
  },
  likesUnlock: {
    category: 'in_app_action_likes',
    action: 'likes_unlock_profile'
  },
  likesClick: {
    category: 'in_app_action_likes',
    action: 'likes_to_profile'
  },
  likesLike: {
    category: 'in_app_action_likes',
    action: 'likes_like_profile'
  },
  bonusCode: {
    category: 'in_app_action_bonus_code',
    action: 'bonus_code'
  },
  toggleDarkmode: {
    category: 'in_app_action_darkmode_web',
    action: 'in_app_action_darkmode_web'
  }
};
