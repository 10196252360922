export default {
    searchAddFriend: { event: 'add_friend', origin: 'search' },
    friendsNewSlots: {
      '25': { event: 'buy_friends', count: 25 },
      '50': { event: 'buy_friends', count: 50 },
      '100': { event: 'buy_friends', count: 100 },
    },
    visitorUnlock: 'unlock_visitor',
    likesUnlock: 'unlock_like',
    profileUnlock: 'unlock_image',
    profileIcebreaker: 'send_icebreaker',
    profileAddFriend: { event: 'add_friend', origin: 'profile' },
    sendGift: {
      ring: { event: 'send_gift', gift: 'ring' },
      diamond: { event: 'send_gift', gift: 'diamond' },
      airplane: { event: 'send_gift', gift: 'airplane' },
      cruise: { event: 'send_gift', gift: 'cruise' },
      champagne: { event: 'send_gift', gift: 'champagne' },
      hotel: { event: 'send_gift', gift: 'hotel' },
      chocolates: { event: 'send_gift', gift: 'chocolates' },
      rose: { event: 'send_gift', gift: 'rose' },
      drink: { event: 'send_gift', gift: 'drink' },
      flower: { event: 'send_gift', gift: 'flower' },
      perfume: { event: 'send_gift', gift: 'perfume' },
      lovedonut: { event: 'send_gift', gift: 'lovedonut' },
      analkugeln: { event: 'send_gift', gift: 'analkugeln' },
      antrag: { event: 'send_gift', gift: 'antrag' },
      banane: { event: 'send_gift', gift: 'banane' },
      bett: { event: 'send_gift', gift: 'bett' },
      BH: { event: 'send_gift', gift: 'BH' },
      bigblackdildo: { event: 'send_gift', gift: 'bigblackdildo' },
      blumen: { event: 'send_gift', gift: 'blumen' },
      cupid: { event: 'send_gift', gift: 'cupid' },
      diamant: { event: 'send_gift', gift: 'diamant' },
      dildo: { event: 'send_gift', gift: 'dildo' },
      domina: { event: 'send_gift', gift: 'domina' },
      familie: { event: 'send_gift', gift: 'familie' },
      'fast-food': { event: 'send_gift', gift: 'fast-food' },
      geschenk: { event: 'send_gift', gift: 'geschenk' },
      gutschein: { event: 'send_gift', gift: 'gutschein' },
      handschellen: { event: 'send_gift', gift: 'handschellen' },
      heartsArrow: { event: 'send_gift', gift: 'heartsArrow' },
      herzen: { event: 'send_gift', gift: 'herzen' },
      'high-heels': { event: 'send_gift', gift: 'high-heels' },
      hochzeitspaar: { event: 'send_gift', gift: 'hochzeitspaar' },
      intimpiercing: { event: 'send_gift', gift: 'intimpiercing' },
      kaffee: { event: 'send_gift', gift: 'kaffee' },
      kette: { event: 'send_gift', gift: 'kette' },
      kleeblatt: { event: 'send_gift', gift: 'kleeblatt' },
      kondome: { event: 'send_gift', gift: 'kondome' },
      kreutzfahrt: { event: 'send_gift', gift: 'kreutzfahrt' },
      kuchen: { event: 'send_gift', gift: 'kuchen' },
      kuss: { event: 'send_gift', gift: 'kuss' },
      lollipop: { event: 'send_gift', gift: 'lollipop' },
      maske: { event: 'send_gift', gift: 'maske' },
      mundpiercing: { event: 'send_gift', gift: 'mundpiercing' },
      muschi: { event: 'send_gift', gift: 'muschi' },
      ohrringe: { event: 'send_gift', gift: 'ohrringe' },
      panties: { event: 'send_gift', gift: 'panties' },
      peitsche: { event: 'send_gift', gift: 'peitsche' },
      romantischEssen: { event: 'send_gift', gift: 'romantischEssen' },
      sexPuppe: { event: 'send_gift', gift: 'sexPuppe' },
      sexyPo: { event: 'send_gift', gift: 'sexyPo' },
      sternschnuppen: { event: 'send_gift', gift: 'sternschnuppen' },
      strand: { event: 'send_gift', gift: 'strand' },
      teddy: { event: 'send_gift', gift: 'teddy' },
      umarmung: { event: 'send_gift', gift: 'umarmung' },
      umschnalldildo: { event: 'send_gift', gift: 'umschnalldildo' },
      vibrator: { event: 'send_gift', gift: 'vibrator' },
      zungenkuss: { event: 'send_gift', gift: 'zungenkuss' },
    },
    signUp: {
      facebook: { event: 'af_complete_registration', af_registration_method: 'facebook' },
      mail: { event: 'af_complete_registration', af_registration_method: 'email' },
      google: { event: 'af_complete_registration', af_registration_method: 'google' }
    },
    emailVerified: 'email_verified',
    purchase: {
      try: {
        event: 'af_purchase',
        af_content_type: 'try',
        af_content_id: null,
        af_quantity: 1,
        af_revenue: null,
        af_currency: null,
      },
      small: {
        event: 'af_purchase',
        af_content_type: 'small',
        af_content_id: null,
        af_quantity: 1,
        af_revenue: null,
        af_currency: null,
      },
      medium: {
        event: 'af_purchase',
        af_content_type: 'medium',
        af_content_id: null,
        af_quantity: 1,
        af_revenue: null,
        af_currency: null,
      },
      large: {
        event: 'af_purchase',
        af_content_type: 'large',
        af_content_id: null,
        af_quantity: 1,
        af_revenue: null,
        af_currency: null,
      },
      extraLarge: {
        event: 'af_purchase',
        af_content_type: 'extraLarge',
        af_content_id: null,
        af_quantity: 1,
        af_revenue: null,
        af_currency: null,
      },
    },
    sendMessage: {
      profile: { event: 'send_message', origin: 'profile' },
      search: { event: 'send_message', origin: 'search' },
      voting: { event: 'send_message', origin: 'voting' },
      conversations: { event: 'send_message', origin: 'conversations' },
      match: { event: 'send_message', origin: 'match' },
    },
    favorites: 'set_favorite',
    bonusCode: 'redeem_bonus_code'
  };