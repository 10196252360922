const NextI18Next = require('next-i18next').default;
const intervalPlural = require('i18next-intervalplural-postprocessor');
const { localeSubpaths } = require('next/config').default().publicRuntimeConfig;
const path = require('path');

// hier später en auf default
module.exports = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['en', 'de'/*, 'da', 'pt', 'es', 'nb', 'fi'*/],
  defaultNamespace: 'common',
  use: [intervalPlural],
  load: 'languageOnly',
  localeSubpaths,
  localePath: path.resolve('./public/static/locales'),
});
