import { createGlobalStyle } from 'styled-components';
import ThemeProfiles from './ThemeProfiles';
/**
 * GlobalStyle
 */
const GlobalStyle = createGlobalStyle`
@font-face{
              font-family: emoji;
              src: 
                local("Apple Color Emoji"),
                local("Noto Color Emoji"),
                local("Android Emoji"),
                local("Segoe UI Emoji"),
                local("EmojiSymbols");
              unicode-range:
                U+1f300-1f5ff, /* misc symbols + pictographs */
                U+1f910-1f918, U+1f980-1f984, /* supplemental */
                U+1f600-1f64f, /* emoticons */
                U+1f680-1f6ff, /* transport */
                U+26??, /* misc */
                U+2700-27bf; /* dingbats */
            }
html {
  height: 100%;
}
  body {
    margin: 0;
    padding: 0;
    font-family: emoji, Quicksand, Arial, Helvetica, sans-serif;
    font-weight: 300;
    height: 100%;
    /* background-color: ${ThemeProfiles.themeLight.backgroundPrimary}; */
    @media (prefers-color-scheme: dark) {
      color: ${ThemeProfiles.themeDark.backgroundPrimary};
    };
  }
  #__next{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${ThemeProfiles.themeLight.backgroundPrimary};
    
    @media (prefers-color-scheme: dark) {
      background-color: ${ThemeProfiles.themeDark.backgroundPrimary};
    };
    
    overflow: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
    width: 0px;
    };
  }
  
  input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus,
 input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px ${ThemeProfiles.themeLight.input} inset !important;
    -webkit-text-fill-color: ${ThemeProfiles.themeLight.inputColor} !important;
    -webkit-transition-delay: 99999s;
    @media (prefers-color-scheme: dark) {
      -webkit-box-shadow: 0 0 0px 1000px ${ThemeProfiles.themeDark.input} inset !important;
      -webkit-text-fill-color: ${ThemeProfiles.themeDark.inputColor} !important;
    };
  }
`;

export default GlobalStyle;
