import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import StyleGuide from './StyleGuide';
import { RoundedButton, Input } from './ThemedComponents';

const NewInput = styled(Input)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
/**
 * Custom Submit Button
 */

 const getColor = (background, gradient, theme) => {
  if(gradient)
  return theme.backgroundGradient;
  if(background)
  return background;

  return theme.main;
 }

const SubmitButton = ({
  name, margin, backgroundColor, fontColor, disabled, flex, gradient, ...props
}) => {
  const theme = useContext(ThemeContext);
return(
  <RoundedButton
    margin={margin || '15px 0px'}
    flex={flex}
    backgroundColor={getColor(backgroundColor, gradient, theme)}
    style={{opacity: disabled ? 0.5 : 1}}
  >
    <NewInput
      type="submit"
      value={name}
      color={fontColor || StyleGuide.colors.fontOnPrimary}
      {...props}
    />
  </RoundedButton>
  );}

export default SubmitButton;
