import { call, put, takeLatest, takeEvery, select, take, takeLeading } from 'redux-saga/effects';
import Router from 'next/router';
import {
  RequestNewPassword,
  Logout,
  VerifyMail,
  DeleteUser,
  Login,
  Register,
  SendErrorLog,
} from '../services/webAppEndPoint';
import uploadImage from '../services/s3EndPoint';
import { getAutoComplete, getPlace } from '../services/placesEndPoint';
import loginActions from './actions';
import types from './types';
import { userInformationActions } from '../userInformation';
import { tokenActions } from '../token';

export const geocodeByPlaceId = (placeId) => {
  const geocoder = new window.google.maps.Geocoder();
  const { OK } = window.google.maps.GeocoderStatus;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      const {
        geometry: {
          location: { lat, lng },
        },
        address_components: addrComp,
      } = results[0];
      const longitude = lng();
      const latitude = lat();
      resolve({
        longitude,
        latitude,
        city: addrComp.find(({ types }) =>
          types.find((el) => el === 'locality' || 'administrative_area_level_3')
        ).long_name,
        country: addrComp.find(({ types }) => types.find((el) => el === 'country')).long_name,
      });
    });
  });
};

function* getLocations({ location }) {
  try {
    let sessionToken = yield select(({ login: { sessionToken: token } }) => token);
    if (sessionToken === null) {
      sessionToken = (Math.random() + 1).toString(36).substring(2);
      yield put(loginActions.setSessionToken(sessionToken));
    }
    const result = yield call(getAutoComplete, location, sessionToken);
    yield put(loginActions.getLocationsSuccess(result));
  } catch (error) {
    yield put(loginActions.getLocationsFailure(error));
  }
}

function* handleGetLocations() {
  yield takeLatest(types.getLocations, getLocations);
}

function* logout() {
  try {
    yield call(Logout);
    yield put(tokenActions.removeToken());
  } catch (error) {
    console.warn('Could not logout');
  }
}

function* handleLogout() {
  yield takeEvery(types.logout, logout);
}

function* requestPassword({ email }) {
  try {
    yield call(RequestNewPassword, email);
    yield put(loginActions.requestPasswordResetSuccess());
  } catch (error) {
    yield put(loginActions.requestPasswordResetFailure(error));
  }
}

function* handleRequestPassword() {
  yield takeLatest(types.requestPasswordReset, requestPassword);
}

function* sendVerify() {
  while (true) {
    const { hash } = yield take(types.verifyMail);
    try {
      yield call(VerifyMail, hash);
    } catch (error) {
      console.warn('Verification unsuccessful', error);
    }
  }
}

function* onDeleteAccount() {
  yield put(tokenActions.removeToken());
  Router.push('/');
}

function* handleTokenOnDelete() {
  yield takeEvery(types.deleteAccountSuccess, onDeleteAccount);
}

function* deleteAccount({ reason, message }) {
  try {
    yield call(DeleteUser, reason, message || 'unknown');
    yield put(loginActions.deleteAccountSuccess());
  } catch (error) {
    yield put(loginActions.deleteAccountFailure(error));
  }
}

function* handleDeleteAccount() {
  yield takeEvery(types.deleteAccount, deleteAccount);
}

function* onLogin({ email, password, token, userId, origin }) {
  try {
    const { statusCode, ...information } = yield call(Login, {
      email,
      password,
      token,
      userId,
      origin,
      deviceId: navigator.userAgent,
    });
    yield put(userInformationActions.updateInformation(information));
    yield put(loginActions.loginSuccess(statusCode));
  } catch (error) {
    yield put(loginActions.loginFailure({ error, email, userId, origin }));
  }
}

function* handleLogin() {
  yield takeLeading(types.login, onLogin);
}

function* onRegister({
  origin,
  token,
  userId,
  location,
  name,
  picture,
  gender,
  birthday,
  email,
  password,
  previewPath,
  locationId,
  leadPage,
}) {
  try {
    console.log(leadPage, 'ABC');
    const fileName = `${picture.id}.${picture.mimeType.split('/')[1]}`;
    // Bild hochladen
    yield call(uploadImage, { file: picture?.uri, fileName: picture?.id });

    let geoLocation = location || {};
    if (location && location.place_id) {
      geoLocation = yield call(geocodeByPlaceId, location.place_id);
    }
    const { latitude: lat, longitude: lng, city, country } = geoLocation;

    const uploadedImage = { ...picture, fileName };

    const { statusCode, ...information } = yield call(Register, {
      origin,
      token,
      userId,
      lat,
      lng,
      country,
      city,
      name,
      gender,
      birthday,
      image: uploadedImage,
      email,
      password,
      deviceId: navigator.userAgent,
      locationId,
      leadPage,
    });

    const { profile } = information;
    const editedProfile = { ...profile, image: { uri: previewPath } };
    const editedImage = { ...uploadedImage, uri: previewPath };

    yield put(
      userInformationActions.updateInformation({
        ...information,
        profile: editedProfile,
        image: editedImage,
      })
    );
    yield put(loginActions.registerSuccess(statusCode));
  } catch (error) {
    yield put(loginActions.registerFailure(error));
  }
}

function* handleRegister() {
  yield takeLeading(types.register, onRegister);
}

export default {
  handleGetLocations,
  handleLogout,
  handleRequestPassword,
  sendVerify,
  handleDeleteAccount,
  handleTokenOnDelete,
  handleLogin,
  handleRegister,
};
